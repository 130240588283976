.admin-container
  display: flex
  justify-content: center
  align-content: center
  height: calc(100vh - 14.8vh)
  flex-direction: column

  .login-wrapper
    width: 100%
    margin: 0 auto
