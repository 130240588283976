.chip
    width: max-content
    padding: 8px 14px
    border-radius: 5px
    font-size: 0.8rem
    border: 1px solid $grey-light

    &:hover
        background: $primary
        color: $white
