nav
  .nav-address
    color: $white
    background-color: $primary
    display: flex
    height: 30px
    align-items: center
    flex-direction: row-reverse

    div
      margin-right: $header-x-spacer
      font-size: 0.8rem

      span
        padding: 0 $header-x-spacer
        border-right: 1px solid white
        &:last-child
          border-right: none
          padding-right: 0

  .nav-navigations
    display: flex
    justify-content: space-between
    padding: 5px $header-x-spacer
    align-items: center
    img
      width: 150px

    .links
      a
        color: $black
        font-weight: bold
        margin-right: 10px
        transition: border-bottom 200ms
        &:last-child
          margin-right: 0px

        &.active
          padding-bottom: 5px
          border-bottom: 3px solid $primary

        &:hover
          padding-bottom: 5px
          border-bottom: 3px solid $primary
          transition: border-bottom 200ms

    #help-us-dropdown
      list-style-type: none
      position: absolute
      z-index: 1
      right: 0
      background: $primary
      margin-top: 23px
      border-radius: $border-radius
      padding: 0
      box-shadow: 0px 6px 10px 0px #00000069

      @media screen and ( max-width: $xs)
        position: relative

      li
        padding: 15px 20px
        transition: all 200ms
        &:hover
          background: $grey-light
          a
            color: $black
        a
          color: $white
          &:hover
            border: none

    i
      display: none

@media screen and ( max-width: 445px )
  nav
    .nav-address
      height: 5px
      div
        display: none

    .nav-navigations
      img
        width: 100px
      .links
        display: none
        width: 100%
        flex-direction: column
        margin: -11px
        padding: 10px
        background: rgb(255 255 255)
        position: fixed
        top: 11px
        z-index: 1
        height: 100vh
        font-size: 4em
        transition: all 200ms
        overflow: scroll
      .links-visible
        display: flex
        a
          margin-bottom: 2rem
        i
          display: block
          font-size: 1em
          text-align: right
      i
        display: block
        font-size: 25px
        color: $secondary
        cursor: pointer

@media screen and ( min-width: 445px ) and ( max-width: $xs )
  nav
    .nav-address
      justify-content: center
      div
        font-size: 0.7rem

    .nav-navigations
      img
        width: 100px
      .links
        display: none
        width: 100%
        flex-direction: column
        margin: -11px
        padding: 10px
        background: rgb(255 255 255)
        position: fixed
        top: 11px
        z-index: 1
        height: 100vh
        font-size: 4em
        transition: all 200ms
        overflow: scroll
      .links-visible
        display: flex
        a
          margin-bottom: 2rem
        i
          display: block
          font-size: 1em
          text-align: right
      i
        display: block
        font-size: 25px
        color: $secondary
        cursor: pointer
