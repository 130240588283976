$border: 1px solid black
table
  width: 100%
  border: $border
  thead
    border: $border
    text-transform: uppercase
    td
      font-weight: bold
  th, td
    padding: 1rem
    text-align: center

  tr
    border-bottom: $border
    img
      width: 300px
      margin-bottom: 10px
