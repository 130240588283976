.news-cards
  display: grid
  grid-template-columns: 1fr
  grid-gap: 1.5rem

  .card-image
    display: inherit
    grid-template-columns: 2fr 3fr
    grid-gap: 1.5rem
    margin-bottom: 1.5rem

    img
      object-fit: contain !important

    @media screen and ( max-width: $xs )
      grid-template-columns: 1fr
    // img
    //   height: 300px
    //   @media screen and ( max-width: $xs )
    //     height: 250px

    .card-text
      padding: 0
      p
        margin-bottom: 10px
        font-weight: 100
      button
        align-self: end
