.m
    &t-1
        margin-top: 1rem
    &t-2
        margin-top: 2rem
    &t-3
        margin-top: 3rem
    &t-4
        margin-top: 4rem
    &t-5
        margin-top: 5rem

    &l-1
        margin-left: 1rem
    &l-2
        margin-left: 2rem
    &l-3
        margin-left: 3rem
    &l-4
        margin-left: 4rem
    &l-5
        margin-left: 5rem

    &b-1
        margin-bottom: 1rem
    &b-2
        margin-bottom: 2rem
    &b-3
        margin-bottom: 3rem
    &b-4
        margin-bottom: 4rem
    &b-5
        margin-bottom: 5rem

    &r-1
        margin-right: 1rem
    &r-2
        margin-right: 2rem
    &r-3
        margin-right: 3rem
    &r-4
        margin-right: 4rem
    &r-5
        margin-right: 5rem

.bg
    &-primary
        background-color: $primary
    &-secondary
        background-color: $secondary
    &-info
        background-color: $info
    &-danger
        background-color: $danger
    &-success
        background-color: $success
    &-black
        background-color: $black
    &-grey
        background-color: $grey
    &-grey-light
        background-color: $grey-light
    &-white
        background-color: $white

.d
    &-block
        display: block
    &-none
        display: none
    &-hidden
        visibility: hidden
    &-visible
        visibility: visible
