$active: #29b89d
.btn
    background: $primary
    padding: 10px 20px
    color: $white
    text-align: center
    border-radius: $border-radius
    cursor: pointer
    border: none
    transition: all 200ms
    &:hover
        background: $secondary
    &:focus
        outline: none
    &:active
        background: #5e5c5cfa
    &-block
        display: block
    &-secondary
        background: $secondary
        &:hover
            background: $primary
        &:active
            background: $active
    &-dark
        background: $black
    &-white
        background: $white
        color: $black
        &:hover
            background: $primary
            color: $white
        &:active
            background: $active
    &-shadow
        box-shadow: 1px 2px 5px 1px #00000042

    // Size Variants
    &-lg
        padding: 18px 40px
        font-size: 1.2rem
