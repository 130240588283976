label
  display: block
  margin-bottom: 10px
.donate-form
  display: grid
  grid-template-areas: "name email" "payment payment" "cvv card"
  grid-gap: 1.5rem

  .name
    grid-area: name
  .email
    grid-area: email
  .payment
    grid-area: payment
    label
      margin: 0
    &-options
      display: flex
      flex-direction: row
      align-items: center
      label
        margin-right: 1rem
      input
        margin-right: 0.2rem
  .cvv
    grid-area: cvv
  .card
    grid-area: card
  button
    width: fit-content

  @media screen and ( max-width: $xs )
    grid-template-areas: "name" "email" "payment" "cvv" "card"

.volunteer-form
  display: grid
  grid-template-areas: "name email phone" "description description description"
  grid-gap: 1.5rem

  .name
    grid-area: name
  .email
    grid-area: email
  .description
    grid-area: description
    textarea
      width: 100%
      padding: 10px
  .phone
    grid-area: phone
  button
    width: fit-content

  @media screen and ( max-width: $xs )
    grid-template-areas: "name" "email" "phone" "description"

.adopt-form
  display: grid
  grid-template-areas: "name email phone" "animal animal animal" "description description description"
  grid-gap: 1.5rem

  .name
    grid-area: name
  .email
    grid-area: email
  .description
    grid-area: description
    textarea
      width: 100%
      padding: 10px
  .phone
    grid-area: phone
  .animal
    grid-area: animal
  button
    width: fit-content

  @media screen and ( max-width: $xs )
    grid-template-areas: "name" "email" "phone" "animal" "description"

.report-form
  display: grid
  grid-template-areas: "name email" "description description" "photo photo"
  grid-gap: 1.5rem

  .name
    grid-area: name
  .email
    grid-area: email
  .description
    grid-area: description
    textarea
      width: 100%
      padding: 10px
  .photo
    grid-area: photo
    align-self: self-end
    label
      margin-bottom: 0

    input
      display: none
  button
    width: fit-content

  @media screen and ( max-width: $xs )
    grid-template-areas: "name" "email" "description" "photo"
