%card-base
    box-shadow: 0px 3px 5px 3px #e8e8e8
    transition: box-shadow 0.3s
    border-radius: $border-radius

.card-image
    // width: 400px
    @extend %card-base
    box-shadow: none
    img
        width: 100%
        height: 300px
        object-fit: cover
        border-radius: $border-radius

    .card-title
        margin-bottom: 5px

    .card-text
        position: relative
        padding: 1rem

        a
            text-decoration: none
            font-weight: bold

        a.floating-text
            position: absolute
            color: $secondary
            right: 20px
            top: -25px
            background: white
            padding: 10px 20px
            border-radius: 25px
            width: fit-content

.card-icon
    // width: 400px
    @extend %card-base

    .card-title
        margin-bottom: 5px

    .card-text
        position: relative
        display: grid
        grid-gap: 1.5rem
        padding: 3rem 5rem

        img
            width: 100px
            height: 100px
            object-fit: contain
            margin: 0 auto

        a
            text-decoration: none
            font-weight: bold

        a.floating-text
            position: absolute
            color: $secondary
            right: 20px
            top: -25px
            background: white
            padding: 10px 20px
            border-radius: 25px
            width: fit-content
    &:hover
        box-shadow: 2px 3px 20px 10px #e8e8e8
