input[type="text"],input[type="email"], input[type="number"], input[type="file"], input[type="password"], input[type="date"]
  width: 100%
  padding: 10px 20px 10px 10px
  border: 0
  box-shadow: 1px 1px 0px 2px #cccccc7d
  border-radius: $border-radius

  &::placeholder
    opacity: 0.7

label
  font-weight: lighter

select
  padding: 10px 30px 10px 6px
  border: 0
  box-shadow: 1px 1px 0px 2px #cccccc7d
  border-radius: $border-radius
