@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&display=swap')
@import 'base'
@import 'theme/color'
@import 'utls/all'
@import 'components/header'
@import 'components/container'
@import 'components/chips'
@import 'components/button'
@import 'components/card'
@import 'components/input'
@import 'components/table'
@import 'components/footer'
@import 'components/nav'
@import 'components/adminnav'
@import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css'

@import './home'
@import './about'
@import './news'
@import './forms'

@import './admin'

// Global overrides
::selection
  background: $primary
  color: white

::-moz-selection
  background: $primary
  color: white
