$primary: #5AD0BB
$primary-transparent: #54d0bbb3
$secondary: #666666
$danger: #d03030
$info: #03a9f4
$success: #4caf50
$black: #333333
$grey: #cccccc
$grey-light: #F5F5F5
$transparent: #03030300
$white: #fff
