.slick-prev:before, .slick-next:before
  font-size: 30px !important
.carousel
  // height: 80vh
  height: 50em

  &-item
    position: relative
    height: 50em
    img
      width: 100%
      height: 50em
      object-fit: cover

    &-overlay
      position: absolute
      width: 100%
      top: 0
      height: 50em
      display: flex
      align-items: center

      &-left
        margin-left: 20px
        padding: 3rem 4rem
        background: rgb(84 208 187 / 70%)
        color: rgb(255 255 255)
        border-radius: 7px
        width: 40%

      .hotline-button
        position: absolute
        right: 30px
        top: 0
        font-weight: bold

      .report-button
        position: absolute
        right: 0
        bottom: 0
        border-radius: 0

.icon-based-cards
  display: grid
  grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) )
  grid-gap: 1.5rem

  // .icon-card-left, .icon-card-right
  //   height: 85%
  //   align-self: end

.image-based-cards
  display: grid
  grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) )
  grid-gap: 1.5rem

  .card-image
    .card-text
      padding: 0
      p
        margin-bottom: 10px
        font-weight: 100
      button
        align-self: end

.our-story
  display: grid
  grid-template-columns: 4fr 2fr
  color: $white
  align-items: center
  grid-gap: 1.5rem

  div
    padding-right: 1rem

  img
    width: 100%
    height: 100%
    object-fit: cover
    border-radius: $border-radius + 50

.events
  &-calendars
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))
    grid-gap: 1.5rem

    div
      padding: 1rem
      border-radius: $border-radius + 10
      border-top: 20px solid $primary
      background-color: $grey-light
      text-align: center
      box-shadow: 1px 1px 0px 2px #cccccc7d

      h1
        margin-top: 0
        color: $primary

.contact
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
  grid-gap: 1.5rem
  position: relative

  .map-area
    .map
      width: 100%
      height: 15rem
      border: 0

  .newsletter-area
    .form
      display: grid
      grid-template-columns: 1fr 1fr
      grid-gap: 1.5rem

      &-last
        grid-column: 1/span 2
  .button-overlay
    position: absolute
    bottom: 0
    right: 9%
    border-radius: 0
    font-weight: bold

@media screen and (max-width: $sm)
  .carousel-item-overlay-left
    margin: 0 20px
    padding: 2rem
    width: 100%

    .display-5
      font-size: 4em

  .icon-based-cards
    margin: 20px

    .icon-card-left, .icon-card-right
      height: 100%

  .our-story
    grid-template-columns: 1fr

    img
      height: auto
      border-radius: 0

@media screen and (min-width: $sm) and (max-width: $md)
  .carousel-item-overlay-left
    margin: 0 20px
    padding: 1rem
    width: 50%
