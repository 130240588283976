.text
    &-title
        font-weight: 900
    &-primary
        color: $primary
    &-secondary
        color: $secondary
    &-info
        color: $info
    &-danger
        color: $danger
    &-success
        color: $success
    &-black
        color: $black
    &-grey
        color: $grey
    &-grey-light
        color: $grey-light
    &-white
        color: $white
    &-bold
        font-weight: bold
    &-light
        font-weight: lighter
    &-center
        text-align: center
    &-left
        text-align: left
    &-right
        text-align: right

    // Text Helpers
    &-strike
        text-decoration: line-through

    &-two-line
        line-height: 0.1

.display
    &-1
        font-size: 1em
    &-2
        font-size: 2em
    &-3
        font-size: 3em
    &-4
        font-size: 4em
    &-5
        font-size: 5em
    &-6
        font-size: 6em

p
    margin-block-start: 0
    margin-block-end: 0
    line-height: 1.8

hr
    color: $grey-light
