footer
  .pre
    padding: $section-padding
    background: $primary
    color: $white
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))
    grid-gap: 1.5rem
    @media screen and ( max-width: $xs)
      padding: $xs-section-padding

    .social-area
      @media screen and ( max-width:  $xs)
        margin: 0 auto
        text-align: center
      h1
        width: fit-content
        margin: 0 0 1.5rem 0
        padding-bottom: 5px

      &-buttons
        i
          font-size: 20px
          margin-right: 10px

    .info
      display: flex
      text-align: right
      flex-direction: column
      justify-content: space-between
      @media screen and ( max-width:  $xs)
        text-align: center

  .post
    padding: 1rem 5rem
    font-size: 0.8rem
    background: $secondary
    color: $white
    display: flex
    flex-direction: row
    justify-content: space-between

    @media screen and ( max-width: $xs)
      flex-direction: column
      text-align: center
    a
      &::after
        content: " |"
      &:last-child
        &::after
          content: none
