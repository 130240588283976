.story
  h2
    margin: 0 0 2rem 0
.board-members
  display: grid
  grid-template-columns: 1fr 4fr
  grid-gap: 1.5rem
  margin-bottom: 1rem

  img
    width: 100%

.partner-organizations
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))
  grid-gap: 1.5rem
  margin-bottom: 1rem

  div
    text-align: center

    img
      width: 60%
